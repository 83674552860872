import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import styles from './WalletCardButton.module.scss';
import CredentialService from 'services/api/CredentialService';
import { UserContext } from 'components/UserGuard';
import Modal from 'components/shared/Modal';
import claimWalletCardIcon from 'assets/icons/claimWalletCard.svg';
import replaceWalletCardIcon from 'assets/icons/replaceWalletCard.svg';
import processingWalletCardIcon from 'assets/icons/processingWalletCard.svg';
import { tenantConfig } from 'config';
import { useSnackbar } from 'notistack';
import { isAddressInternational, willAddressShip } from 'util/Form';
import cardpos from 'assets/icons/card-pos.svg';
import { SMALL_WINDOW_WIDTH, useWindowSize } from 'util/Window';
import { DISPLAY_WALLET_CARD_POPUP_LABEL, getExpDate } from 'util/LocalStorage';
import classNames from 'classnames';

const WalletCardButton: React.FunctionComponent = () => {
  const { width, isMobile } = useWindowSize();
  const {
    nccerCardNumber,
    walletCardStatus,
    address,
    applyFerpaAgeRestriction,
    canClaimWalletCard,
    birthDate,
    refetchUser,
  } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const additionalSubTitleText = applyFerpaAgeRestriction
    ? 'It will be sent to your organization or school.'
    : 'It will be sent to the address in your account profile.';
  const [walletCardOpen, setWalletCardOpen] = useState<boolean>(false);
  const [walletCardSuccessOpen, setWalletCardSuccessOpen] = useState<boolean>(false);
  const [walletCardBatchOpen, setWalletCardBatchOpen] = useState<boolean>(false);
  const [walletTitle, setWalletTitle] = useState<string>('Claim Wallet Card');
  const [walletSubTitle, setWalletSubTitle] = useState<string>(
    'Good News! Your first wallet card is FREE and ready to be claimed. ' + additionalSubTitleText
  );
  const [walletOrderBtn, setWalletOrderBtn] = useState<string>('Claim My Card');
  const addressAlertText =
    "Looks like there's no address on your account profile. Click 'Settings' to edit your profile and enter your address.";
  const birthDateAlertText =
    "Looks like there's no date of birth on your account profile. Click 'Settings' to edit your profile and enter your date of birth.";
  const LocalStorageLabel = DISPLAY_WALLET_CARD_POPUP_LABEL + nccerCardNumber;
  const isBirthDateNull = birthDate === null || birthDate === undefined;

  // sets whether to hide the modal popup on page load
  const setPopupHiddenStoredValue = useCallback(
    (value: boolean) => {
      const storedValue = {
        value: value,
        expiry: getExpDate(1), // hide for a day
      };
      localStorage.setItem(LocalStorageLabel, JSON.stringify(storedValue));
    },
    [LocalStorageLabel]
  );

  const determinePopupState = (): boolean => {
    const storedValueStr = localStorage.getItem(LocalStorageLabel);
    if (storedValueStr) {
      const storedValue = JSON.parse(storedValueStr);
      const now = new Date();

      if (storedValue.expiry > now.getTime() && storedValue.value) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const getBatchedSubTitle = useCallback(() => {
    if (isAddressInternational(address)) {
      return 'Wallet cards for individuals with international mailing addresses will be sent to their NCCER training program.';
    } else if (applyFerpaAgeRestriction) {
      return 'In accordance with privacy regulations, NCCER cannot collect or store home addresses for individuals under the age of 18. Your wallet card will be sent to your school.';
    } else {
      return 'Your wallet card is being mailed to your organization. Please claim it from them.';
    }
  }, [address]);

  const pageLoad = async () => {
    setLoading(true);
    // implement a 'dont bother me with this again' button?
    if (canClaimWalletCard && !isBirthDateNull && determinePopupState() && !isWalletCardClaimed()) {
      handleWalletCardModalOpen();
    }
    setLoading(false);
  };

  useEffect(() => {
    determinePopupState();
    pageLoad();
  }, []);

  // set card modal info
  useEffect(() => {
    if (isWalletCardClaimed()) {
      setWalletTitle('Replace Card');
      setWalletSubTitle(
        "Looks like you've already claimed your free wallet card.  Click below to purchase a replacement card."
      );
      setWalletOrderBtn('Replace Card');
    }
  }, [walletCardStatus]);

  const handleWalletCardModalOpen = useCallback(() => {
    // perform DoB check
    if (isBirthDateNull) {
      enqueueSnackbar(birthDateAlertText, { variant: 'warning', autoHideDuration: 5000 });
    } else if (
      !isWalletCardBatched() &&
      !isWalletCardClaimed() &&
      (applyFerpaAgeRestriction || isAddressInternational(address))
    ) {
      claimWalletCard();
    } else if (isWalletCardBatched()) {
      setWalletCardBatchOpen(true);
    } else {
      setWalletCardOpen(true);
    }
  }, [applyFerpaAgeRestriction, address]);
  const handleWalletCardModalClose = useCallback(() => {
    setWalletCardOpen(false);
    setPopupHiddenStoredValue(true);
  }, []);
  const handleWalletCardSuccessModalClose = useCallback(() => {
    setWalletCardSuccessOpen(false);
    setPopupHiddenStoredValue(true);
  }, []);
  const handleWalletCardBatchModalClose = useCallback(() => {
    setWalletCardBatchOpen(false);
    setPopupHiddenStoredValue(true);
  }, []);

  const isWalletCardClaimed = () => {
    return (
      walletCardStatus == 'Claimed' ||
      walletCardStatus == 'claimed' ||
      walletCardStatus == 'Issued' ||
      walletCardStatus == 'issued'
    );
  };
  const isWalletCardBatched = () => {
    return walletCardStatus == 'Batched' || walletCardStatus == 'batched';
  };

  const claimWalletCard = useCallback(() => {
    if (isWalletCardBatched()) {
      // show batch modal
      setWalletCardOpen(false);
      setWalletCardBatchOpen(true);
    } else if (isWalletCardClaimed()) {
      // route to nopCommerce
      window.open(tenantConfig.replaceWalletCardUrl, '_blank')?.focus();
    } else {
      // perform address check
      if (!willAddressShip(address) && !applyFerpaAgeRestriction) {
        enqueueSnackbar(addressAlertText, { variant: 'warning', autoHideDuration: 5000 });
      } else {
        // fire off wallet claim request
        setLoading(true);
        CredentialService.claimWalletCard()
          .then((response) => {
            setWalletCardOpen(false);
            refetchUser();
            applyFerpaAgeRestriction || isAddressInternational(address)
              ? setWalletCardBatchOpen(true)
              : setWalletCardSuccessOpen(true);
            setLoading(false);
            return response;
          })
          .catch((error) => {
            enqueueSnackbar(error?.body, { variant: 'error' });
            setLoading(false);
            return undefined;
          });
      }
    }
  }, [nccerCardNumber, applyFerpaAgeRestriction, address, refetchUser]);

  return canClaimWalletCard ? (
    <>
      {width <= SMALL_WINDOW_WIDTH + 60 ? (
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={styles.cardButton}
          disabled={loading}
          onClick={handleWalletCardModalOpen}
        >
          <img src={cardpos} className={classNames(commonStyles.buttonIcon, styles.smallButtonIcon)} />
          {isMobile && 'Claim Wallet Card'}
        </Button>
      ) : (
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={styles.cardButton}
          disabled={loading}
          onClick={handleWalletCardModalOpen}
        >
          <img src={cardpos} className={commonStyles.buttonIcon} />
          {'Claim Wallet Card'}
        </Button>
      )}
      {/* WC Claim Prompt */}
      <Modal
        loading={loading}
        open={walletCardOpen}
        title={walletTitle}
        onClose={handleWalletCardModalClose}
        subTitleText={walletSubTitle}
        actions={
          <>
            <Button
              fullWidth={true}
              variant={'contained'}
              color={'primary'}
              onClick={claimWalletCard}
              className={styles.walletCardActionBtn}
            >
              {walletOrderBtn}
            </Button>
            <Button fullWidth={true} variant={'outlined'} color={'secondary'} onClick={handleWalletCardModalClose}>
              {'No Thanks'}
            </Button>
          </>
        }
      >
        <div className={styles.cardIcon}>
          {isWalletCardClaimed() && <img src={replaceWalletCardIcon} />}
          {!isWalletCardClaimed() && <img src={claimWalletCardIcon} />}
        </div>
      </Modal>
      {/* WC Claim Success Prompt */}
      <Modal
        loading={loading}
        open={walletCardSuccessOpen}
        title={'Your Card Is On Its Way'}
        onClose={handleWalletCardSuccessModalClose}
        subTitleText={
          'Hang tight - we are working on printing your wallet card and it should be at your door step soon.'
        }
        actions={
          <Button fullWidth={true} variant={'outlined'} color={'secondary'} onClick={handleWalletCardSuccessModalClose}>
            {'Close'}
          </Button>
        }
      >
        <div className={styles.cardIcon}>
          <img src={processingWalletCardIcon} />
        </div>
      </Modal>
      {/* WC Claim Batch Prompt */}
      <Modal
        loading={loading}
        open={walletCardBatchOpen}
        title={'Claim Card through Organization'}
        onClose={handleWalletCardBatchModalClose}
        subTitleText={getBatchedSubTitle()}
        actions={
          <Button fullWidth={true} variant={'outlined'} color={'secondary'} onClick={handleWalletCardBatchModalClose}>
            {'Close'}
          </Button>
        }
      >
        <div className={styles.cardIcon}>
          <img src={claimWalletCardIcon} />
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default WalletCardButton;
