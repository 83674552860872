import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import {
  countryFieldName,
  FormProps,
  getAddressControlName,
  getRequiredValidationRule,
  getValidationProps,
} from 'util/Form';
import countries from 'store/configs/Countries';
import StaticSelectOption from 'store/types/StaticSelectOption';

import externalStyles from 'components/profile/EditProfilePageView/EditProfileForm/EditProfileForm.module.scss';

interface CountryFormItemProps extends FormProps {
  addressFieldName: string;
  disabled?: boolean;
}

const options: React.ReactNode[] = countries.map(({ text, value }: StaticSelectOption) => (
  <MenuItem value={value} key={`country-${value}`}>
    {text}
  </MenuItem>
));
const fieldName = countryFieldName;

const CountryFormItem: React.FunctionComponent<CountryFormItemProps> = ({
  addressFieldName,
  required = false,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const addressErrors = addressFieldName ? (errors[addressFieldName] as FieldErrors) : errors;
  const controlName = useMemo(() => getAddressControlName(addressFieldName, fieldName), [addressFieldName]);

  const handleChange = useCallback(
    (onChange, onBlur) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
      onBlur();
    },
    []
  );

  return (
    <Controller
      render={({ field: { onChange, value, onBlur } }) => (
        <TextField
          {...getValidationProps(fieldName, addressErrors)}
          value={value}
          select={true}
          onChange={handleChange(onChange, onBlur)}
          disabled={disabled}
          //label={'Country'}
          required={required}
          className={externalStyles.inputField}
          inputProps={{ 'data-testid': `${fieldName}-input` }}
          data-testid={`${fieldName}-text-field`}
          FormHelperTextProps={{ 'data-testid': `${fieldName}-helper-text` } as any}
        >
          {options}
        </TextField>
      )}
      name={controlName}
      control={control}
      rules={{ required: getRequiredValidationRule('country', true, required) }}
    />
  );
};
export default CountryFormItem;
