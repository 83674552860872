import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import commonStyles from 'styles/common.module.scss';
import styles from '../OnlineVerification/OnlineVerification.module.scss';
import { Credential, Credentials } from 'store/types/Credentials';
import CredentialService from 'services/api/CredentialService';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import searchNormal from 'assets/icons/searchNormal.svg';
import { useSnackbar } from 'notistack';
import sideCarrot from 'assets/icons/sideCarrot.svg';
import NCCER_logo_wordmark_positive_RGB from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import routes from 'store/configs/Routes';
import { Completion, Completions } from 'store/types/Completions';
import { getDocLink } from 'util/Format';
import { isTraining, isVerification, isCertification } from 'util/Credentials';
import TabPanel from 'components/shared/TabPanel';
import classNames from 'classnames';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CompletionCard from 'components/credentials/CompletionCard';
import CredentialCard from 'components/credentials/CredentialCard';
import { TableColumn } from 'store/types/Table';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import Table from 'components/shared/Table';
import moment from 'moment';
import { pipelineTypes } from 'store/configs/CredentialTypes';
import CraftProCard from '../CraftProCard';
import { GridView, InfoOutlined, ViewAgenda, ViewAgendaOutlined } from '@mui/icons-material';

const OnlineVerificationSearch: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [credsState, setCredsState] = useState<Completions>();
  const [searchVal, setSearchVal] = React.useState<string>();
  const { nccerNumber } = useParams<{ nccerNumber: string }>();
  const [tabValue, setTabValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [viewType, setViewType] = React.useState<boolean>(true); // false = list/grid, true = tile
  const paginationLength = 10;

  const trainingModules = credsState?.Completions?.filter(
    (item) => item.Content.Type === 'Module' && !item.Content.Format && !pipelineTypes.includes(item.Content.Family)
  )
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId))
    .sort((a, b) => a.Content.Family?.localeCompare(b.Content.Family));
  const trainingLevels = credsState?.Completions?.filter((item) => item.Content.Type === 'Level').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingCrafts = credsState?.Completions?.filter((item) => item.Content.Type === 'Craft').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingTests = credsState?.Credentials?.filter((item) => item.ContentType == 'Test').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const trainingCount =
    (trainingModules?.length ?? 0) +
    (trainingLevels?.length ?? 0) +
    (trainingCrafts?.length ?? 0) +
    (trainingTests?.length ?? 0);

  const knowledgeVerifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Knowledge Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const performanceVerifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Performance Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const jlaCount = (knowledgeVerifications?.length ?? 0) + (performanceVerifications?.length ?? 0);

  const craftCertifications = credsState?.Credentials?.filter((item) => item.ContentType == 'Certified').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const personnelCertifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Program Personnel'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const certificateCount = (craftCertifications?.length ?? 0) + (personnelCertifications?.length ?? 0);

  const fullModulePipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && !item.Content.Format
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const writtenPipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Written'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const performancePipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Performance'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const pipelineCount =
    (fullModulePipelineCompletions?.length ?? 0) +
    (writtenPipelineCompletions?.length ?? 0) +
    (performancePipelineCompletions?.length ?? 0);

  const cpCourses = credsState?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Course', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const cpLearningPaths = credsState?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Path', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const craftProCount = (cpCourses?.length ?? 0) + (cpLearningPaths?.length ?? 0);

  let results: Completions | undefined;

  useEffect(() => {
    setSearchVal(nccerNumber);
    searchNumbers();
  }, []);

  const searchNumbers = useCallback(async () => {
    setLoading(true);
    try {
      const results = await CredentialService.publicSearch(searchVal ?? nccerNumber);
      setCredsState(results);
      setLoading(false);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown Server Error.';
      enqueueSnackbar(message, { variant: 'error' });
      setCredsState(undefined);
      setLoading(false);
    }
  }, [searchVal, nccerNumber, enqueueSnackbar]);

  const handleEnterKey = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      //history.push();
      searchNumbers();
    }
  };
  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.currentTarget.value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleListView = useCallback(() => {
    setViewType(false);
  }, []);

  const handleGridView = useCallback(() => {
    setViewType(true);
  }, []);

  const getListIcon = () => {
    if (viewType) {
      return <ViewAgendaOutlined className={commonStyles.listViewIcon} />;
    } else {
      return <ViewAgenda className={commonStyles.listViewIcon} />;
    }
  };

  const getGridIcon = () => {
    if (viewType) {
      return <GridViewRoundedIcon className={commonStyles.gridViewIcon} />;
    } else {
      return <GridView className={commonStyles.gridViewIcon} />;
    }
  };

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error?.body, { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  const pipelineCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
  ];

  const credentialColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'ExpireDate',
      label: <Typography fontWeight="bold">Expiration Date</Typography>,
      render: (_, record) => (
        <Typography>{record.ExpireDate ? moment(record.ExpireDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const assessmentColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const levelCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const moduleCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const trainingTestColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftProCourseColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    // {
    //   dataIndex: "Content",
    //   label: <Typography fontWeight="bold">Craft</Typography>,
    //   render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    // },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftProLearningPathColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  return (
    <>
      <div className={styles.ovHeader}>
        <Grid container>
          <Grid item xs={12} sm={2}>
            <img src={NCCER_logo_wordmark_positive_RGB} className={styles.ovLogo} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant={'h1'} className={styles.searchedTitle}>
              {'Credential Search'}
            </Typography>
            <Typography variant={'h5'} className={styles.searchedDescription}>
              {"Search and verify individuals' credentials"}
            </Typography>
          </Grid>
          {/* <Grid item xs={2}>
          </Grid> */}
        </Grid>
      </div>
      <Spinner loading={loading}>
        <div className={styles.ovSearchedContent}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <TextField
                placeholder={'12345678'}
                value={searchVal}
                size={'small'}
                onChange={handleSearchInput}
                onKeyDown={handleEnterKey}
                className={styles.searchBar}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <img src={searchNormal} />
                    </InputAdornment>
                  ),
                }}
              />
              {credsState && credsState.FirstName && (
                <>
                  <Grid container className={styles.nameplateRow}>
                    <Grid item xs={12} sm={8} className={commonStyles.nameplateWrapper}>
                      <Grid item xs={2} md={1} xl={1} className={commonStyles.smallProfileImageWrapper}>
                        {credsState.LogoUrl ? (
                          <img className={commonStyles.smallProfileImage} src={credsState.LogoUrl} />
                        ) : (
                          <AccountCircleIcon className={commonStyles.smallProfileImage}></AccountCircleIcon>
                        )}
                      </Grid>
                      <Grid item xs={10} md={11} xl={11} className={commonStyles.nameplate}>
                        <Typography variant={'h2'}>
                          {credsState.FirstName} {credsState.LastName}
                        </Typography>
                        <Typography variant={'body2'}>{credsState.CardNumber}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={false}
                    visibleScrollbar={true}
                    className={commonStyles.ovOverflowEnforcer}
                  >
                    <Tab
                      label={'Accredited Training'}
                      icon={
                        <Typography className={commonStyles.credentialCounter}>{trainingCount.toString()}</Typography>
                      }
                      iconPosition={'end'}
                      className={commonStyles.tabText}
                    />
                    <Tab
                      label={'CraftPro'}
                      icon={
                        <Typography className={commonStyles.credentialCounter}>{craftProCount.toString()}</Typography>
                      }
                      iconPosition={'end'}
                      className={commonStyles.tabText}
                    />
                    <Tab
                      label={'Journey-Level Assessments'}
                      icon={<Typography className={commonStyles.credentialCounter}>{jlaCount.toString()}</Typography>}
                      iconPosition={'end'}
                      className={commonStyles.tabText}
                    />
                    <Tab
                      label={'Certifications'}
                      icon={
                        <Typography className={commonStyles.credentialCounter}>
                          {certificateCount.toString()}
                        </Typography>
                      }
                      iconPosition={'end'}
                      className={commonStyles.tabText}
                    />
                    <Tab
                      label={'Pipeline OQ'}
                      icon={
                        <Typography className={commonStyles.credentialCounter}>{pipelineCount.toString()}</Typography>
                      }
                      iconPosition={'end'}
                      className={commonStyles.tabText}
                    />
                  </Tabs>
                  <Grid {...defaultGridContainerProps}>
                    <Grid {...defaultGridItemProps} lg={10} md={9} sm={8}>
                      <Typography variant={'body2'} className={commonStyles.tabDescriptor}>
                        {tabValue == 0 &&
                          'This training is delivered by organizations that have met the NCCER accreditation and personnel training requirements. NCCER curriculum is modular in format and presented as a multi-level curriculum that can be used to meet the DOL Office of Apprenticeship time requirements.'}
                        {tabValue == 1 &&
                          'CraftPro training consists of NCCER online courses selected by a company based on their unique project and skill requirements, and do not require accreditation.'}
                        {tabValue == 2 &&
                          'These assessments and performance verifications are intended to measure the knowledge and skills of experienced craft professionals.'}
                        {tabValue == 3 &&
                          'Certifications represent completion of both knowledge and performance evaluations. Note: this section also displays program personnel certifications.'}
                        {tabValue == 4 &&
                          'Pipeline OQ are written and/or performance completions needed to qualify individuals to perform activities on a pipeline according to operator qualification requirements.'}
                      </Typography>
                    </Grid>
                    <Grid {...defaultGridItemProps} lg={2} md={3} sm={4} className={commonStyles.viewSelectorWrapper}>
                      <ButtonGroup variant={'contained'} className={commonStyles.viewSelector}>
                        <IconButton
                          onClick={handleGridView}
                          className={classNames(
                            commonStyles.gridViewButton,
                            viewType ? commonStyles.activeViewButton : ''
                          )}
                        >
                          {getGridIcon()}
                          <Typography variant={'body2'} className={commonStyles.viewSelectorText}>
                            Grid
                          </Typography>
                        </IconButton>
                        <IconButton
                          onClick={handleListView}
                          className={classNames(
                            commonStyles.listViewButton,
                            !viewType ? commonStyles.activeViewButton : ''
                          )}
                        >
                          {getListIcon()}
                          <Typography variant={'body2'} className={commonStyles.viewSelectorText}>
                            List
                          </Typography>
                        </IconButton>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </>
              )}
              <br />
              <TabPanel value={tabValue} index={0}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={trainingCrafts}
                          columns={craftCompletionColumns}
                          noResultsText="No crafts earned"
                          showPagination={trainingCrafts && trainingCrafts.length > paginationLength}
                          category="Completed Crafts"
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={trainingLevels}
                          columns={levelCompletionColumns}
                          noResultsText="No levels earned"
                          showPagination={trainingLevels && trainingLevels.length > paginationLength}
                          category="Completed Levels"
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={trainingModules}
                          columns={moduleCompletionColumns}
                          noResultsText="No modules earned"
                          showPagination={trainingModules && trainingModules.length > paginationLength}
                          category="Completed Modules"
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={trainingTests}
                          columns={trainingTestColumns}
                          noResultsText="No tests completed"
                          showPagination={trainingTests && trainingTests.length > paginationLength}
                          category="Specialized Test Certificates"
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Craft Completions
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    trainingCrafts &&
                    trainingCrafts!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CompletionCard item={item} showContentType={false} />
                      </Grid>
                    ))}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Level Completions
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    trainingLevels &&
                    trainingLevels.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CompletionCard item={item} showContentType={false} />
                      </Grid>
                    ))}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Module Completions
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    trainingModules &&
                    trainingModules.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CompletionCard item={item} showContentType={false} />
                      </Grid>
                    ))}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Specialized Test Certificates
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    trainingTests &&
                    trainingTests.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CredentialCard item={item} />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={cpLearningPaths}
                          columns={craftProLearningPathColumns}
                          category="Learning Path Credentials"
                          noResultsText="No learning path credentials earned"
                          showPagination={cpLearningPaths && cpLearningPaths.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={cpCourses}
                          columns={craftProCourseColumns}
                          category="Course Completions"
                          noResultsText="No course completions earned"
                          showPagination={cpCourses && cpCourses.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Learning Paths
                        <Tooltip
                          title="Collections of NCCER courses, curated by companies to meet specific training goals."
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#172535',
                                '& .MuiTooltip-arrow': {
                                  color: '#172535',
                                },
                                padding: '12px',
                              },
                            },
                          }}
                        >
                          <IconButton>
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    cpLearningPaths &&
                    cpLearningPaths!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CraftProCard item={item} />
                      </Grid>
                    ))}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        All Courses
                        <Tooltip
                          title="Individual training requirements that make up each Learning Path."
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#172535',
                                '& .MuiTooltip-arrow': {
                                  color: '#172535',
                                },
                                padding: '12px',
                              },
                            },
                          }}
                        >
                          <IconButton>
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    cpCourses &&
                    cpCourses!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CraftProCard item={item} />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={knowledgeVerifications}
                          columns={assessmentColumns}
                          category="Knowledge Verified"
                          noResultsText="No credentials earned"
                          showPagination={knowledgeVerifications && knowledgeVerifications.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={performanceVerifications}
                          columns={assessmentColumns}
                          category="Performance Verified"
                          noResultsText="No credentials earned"
                          showPagination={
                            performanceVerifications && performanceVerifications.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Knowledge Verified
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    knowledgeVerifications &&
                    knowledgeVerifications!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CredentialCard item={item} showContentType={false} />
                      </Grid>
                    ))}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Performance Verified
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    performanceVerifications &&
                    performanceVerifications!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CredentialCard item={item} showContentType={false} />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={personnelCertifications}
                          columns={credentialColumns}
                          category="Program Personnel Certifications"
                          noResultsText="No credentials earned"
                          showPagination={personnelCertifications && personnelCertifications.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={craftCertifications}
                          columns={credentialColumns}
                          category="Craft Certifications"
                          noResultsText="No credentials earned"
                          showPagination={craftCertifications && craftCertifications.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Program Personnel Certifications
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    personnelCertifications &&
                    personnelCertifications!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CredentialCard item={item} />
                      </Grid>
                    ))}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Craft Certifications
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    craftCertifications &&
                    craftCertifications!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CredentialCard item={item} />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={fullModulePipelineCompletions}
                          columns={pipelineCompletionColumns}
                          category="Completed Modules"
                          noResultsText="No module completions earned"
                          showPagination={
                            fullModulePipelineCompletions && fullModulePipelineCompletions.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={writtenPipelineCompletions}
                          columns={pipelineCompletionColumns}
                          category="Written Completions"
                          noResultsText="No written completions earned"
                          showPagination={
                            writtenPipelineCompletions && writtenPipelineCompletions.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={performancePipelineCompletions}
                          columns={pipelineCompletionColumns}
                          category="Performance Completions"
                          noResultsText="No performance completions earned"
                          showPagination={
                            performancePipelineCompletions && performancePipelineCompletions.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Completed Modules
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    fullModulePipelineCompletions &&
                    fullModulePipelineCompletions!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CompletionCard item={item} />
                      </Grid>
                    ))}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Written Completions
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    writtenPipelineCompletions &&
                    writtenPipelineCompletions!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CompletionCard item={item} />
                      </Grid>
                    ))}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                        Performance Completions
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    performancePipelineCompletions &&
                    performancePipelineCompletions!.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CompletionCard item={item} />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </div>
      </Spinner>
    </>
  );
};
export default OnlineVerificationSearch;
