import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from 'components/layout/Header';
import {
  Button,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import commonStyles from 'styles/common.module.scss';
import styles from './VerifyCredentials.module.scss';
import CredentialService from 'services/api/CredentialService';
import routes from 'store/configs/Routes';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Spinner from 'components/shared/Spinner';
import clipboards from 'assets/icons/clipboards.svg';
import { Completions } from 'store/types/Completions';
import searchNormal from 'assets/icons/searchNormal.svg';
import { useSnackbar } from 'notistack';
import sideCarrot from 'assets/icons/sideCarrot.svg';
import CreditEngineService from 'services/api/CreditEngineService';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import classNames from 'classnames';
import { alternateIds } from 'store/configs/AlternateIDs';

const MAX_ROWS = 50;

const VerifyCredentials: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const search = useMemo(() => searchParams.get('search') ?? '', [searchParams]);
  const type = useMemo(() => searchParams.get('type') ?? '', [searchParams]);
  const { isMobile } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [credsList, setCredsList] = useState<Completions[]>();
  const [alternateIdType, setAlternateIdType] = useState(type);
  const [searchVal, setSearchVal] = useState(search);
  const { enqueueSnackbar } = useSnackbar();

  const nccerNumbers = useMemo(() => credsList?.map((e) => e.CardNumber).join(';') ?? '', [credsList]);
  const sortedAlternateIds = useMemo(
    () => [{ name: 'NCCER Number', code: '' }, ...alternateIds.sort((a, b) => a.name.localeCompare(b.name))],
    []
  );
  const alternateIdName = useMemo(
    () => sortedAlternateIds.find((e) => e.code === alternateIdType)?.name ?? 'ID Number',
    [alternateIdType, sortedAlternateIds]
  );

  const searchNumbers = useCallback(
    async (searchText: string, altIdType: string | null | undefined) => {
      setLoading(true);

      try {
        const response = await CredentialService.search(searchText, altIdType);
        const results = response.resultsFound;
        setCredsList(results);
        response.lookupErrors?.forEach((element) => {
          enqueueSnackbar(element, { variant: 'warning' });
        });
      } catch (error) {
        const err = error as { code: number; body: string } | undefined;
        if (err?.code !== 404) {
          enqueueSnackbar(err?.body ? err.body : 'Unknown Error.', { variant: 'error' });
        }
        setCredsList([]);
      }

      setLoading(false);
    },
    [enqueueSnackbar]
  );

  const printVerificationReport = useCallback(() => {
    if (nccerNumbers) {
      CreditEngineService.getVerificationReport(nccerNumbers)
        .then(() => {
          enqueueSnackbar(`Downloading file`, { variant: 'success' });
        })
        .catch((error: string) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    }
  }, [enqueueSnackbar, nccerNumbers]);

  const handleSearch = useCallback(async () => {
    if (!searchVal) return;

    const params = new URLSearchParams({ search: searchVal });
    if (alternateIdType) {
      params.append('type', alternateIdType);
    }
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [searchVal, alternateIdType, history, location.pathname]);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // allow alphanumeric, apostrophes, commas, semicolons, spaces, and new-lines only
    const regex = /^[0-9a-zA-Z',;\-\n \t\b]+$/;
    if (event.currentTarget.value === '' || regex.test(event.currentTarget.value)) {
      const count = (event.currentTarget.value.match(/\n/g) || []).length;
      if (count < MAX_ROWS) {
        setSearchVal(event.currentTarget.value);
      } else {
        setSearchVal(event.currentTarget.value.split('\n', MAX_ROWS).join('\n'));
      }
    }
  };

  const handleChangeIdType = (event: SelectChangeEvent) => {
    setAlternateIdType(event.target.value);
  };

  useEffect(() => {
    // Perform search on page load.
    setSearchVal(search);
    setAlternateIdType(type);

    if (search) {
      searchNumbers(search, type);
    } else {
      setCredsList([]);
    }
  }, [search, searchNumbers, searchParams, type]);

  return (
    <>
      {!isMobile && <Header />}
      <div className={styles.verifyCredentialsHeader}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps}>
            <Typography variant={'h2'} className={styles.title}>
              {'Verify Credentials'}
            </Typography>
            <Typography variant={'body1'} className={styles.description}>
              {'Select ID type and enter search criteria to verify credentials.'}
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <FormControl fullWidth>
              <InputLabel id="id-type-select-label" shrink>
                ID Type
              </InputLabel>
              <Select
                labelId="id-type-select-label"
                id="id-type-select"
                label="ID Type"
                displayEmpty
                notched
                value={alternateIdType}
                onChange={handleChangeIdType}
              >
                {sortedAlternateIds.map(({ name, code }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Typography variant={'body1'} className={styles.description}>
              {'Enter ID number(s). Separate entries by line or paste a list.'}
            </Typography>
          </Grid>
          <Grid
            {...defaultGridItemProps}
            sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '8px' }}
          >
            <TextField
              placeholder={alternateIdType === '' ? '123456' : 'Smith 123-45-6789'}
              value={searchVal}
              onChange={handleSearchInput}
              className={styles.searchBar}
              label={alternateIdType === '' ? alternateIdName : `Last Name and ${alternateIdName}`}
              multiline
              maxRows={10}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <img src={searchNormal} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant={'outlined'}
              color={'secondary'}
              className={styles.searchButton}
              onClick={handleSearch}
              disabled={loading}
            >
              <img src={searchNormal} className={styles.searchIcon} />
              <span className={styles.text}>{'Search'}</span>
            </Button>
            <Button
              variant={'contained'}
              color={'secondary'}
              className={styles.exportButton}
              onClick={printVerificationReport}
              disabled={loading || !credsList?.length}
            >
              <span className={classNames(styles.exportText)}>{'Export Results'}</span>
            </Button>
          </Grid>
        </Grid>
      </div>
      <Spinner loading={loading}>
        <div className={commonStyles.pageContent}>
          {credsList &&
            credsList.map((cred, index) => {
              if (cred.Completions.length > 0 || cred.Credentials.length > 0) {
                return (
                  <Grid container key={index} className={styles.credentialCard}>
                    <Grid item xs={8} className={commonStyles.nameplateWrapper}>
                      <Grid item xs={2} md={1} xl={1} className={commonStyles.smallProfileImageWrapper}>
                        {cred.LogoUrl ? (
                          <img className={commonStyles.smallProfileImage} src={cred.LogoUrl} />
                        ) : (
                          <AccountCircleIcon className={commonStyles.smallProfileImage}></AccountCircleIcon>
                        )}
                      </Grid>
                      <Grid item xs={10} md={11} xl={11} className={commonStyles.nameplate}>
                        <Typography variant={'h2'}>
                          {cred.FirstName} {cred.LastName}
                        </Typography>
                        <Typography variant={'body2'}>{cred.CardNumber}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <NavLink to={routes.viewCredentials + '/' + cred.CardNumber}>
                        <Button size={'small'} className={styles.viewCredsBtn}>
                          <Typography variant={'body1'} className={styles.text}>
                            {'View Credentials'}
                            <img src={sideCarrot} className={styles.carrot} />
                          </Typography>
                        </Button>
                      </NavLink>
                    </Grid>
                  </Grid>
                );
              }
            })}
          {credsList && credsList.length === 0 && !loading && (
            <Grid item xs={12} className={commonStyles.clipboardBox}>
              <img src={clipboards} />
              <Typography variant={'h5'} className={commonStyles.text}>
                {
                  "Sorry, we can't find any records that match your search. Please try re-entering the information and make sure that it is accurate."
                }
              </Typography>
            </Grid>
          )}
        </div>
      </Spinner>
    </>
  );
};
export default VerifyCredentials;
