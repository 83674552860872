import Sidebar from 'components/layout/Sidebar';
import React, { useContext } from 'react';
import { ConfigContext } from 'components/ConfigGuard';
import AppProtectedRoutes from 'components/AppProtectedRoutes';
import { Alert, AlertTitle } from '@mui/material';
import { getLocalStorageValue, IMPERSONATION_FLAG_LS_LABEL } from 'util/LocalStorage';

import styles from './MainPageLayout.module.scss';

const MainPageLayout: React.FunctionComponent = () => {
  const siteConfig = useContext(ConfigContext);
  const { isNccerBillingApp } = siteConfig;
  const isImpersonation = !!getLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL);
  //const user = useContext(UserContext);

  // Per sof-570, removing userflow from portal for the foreseeable future
  // if (tenantConfig.userflowToken) {
  //   userflow.init(tenantConfig.userflowToken);
  //   // we use NCN, or SF ID
  //   userflow.identify(user.nccerCardNumber ?? user.id, {
  //     name: user.firstName + " " + user.lastName,
  //     email: user.email,
  //     //signed_up_at: we do not have this data point
  //   });
  // }

  return (
    <>
      {isImpersonation && (
        <Alert severity={'warning'} className={styles.alert}>
          <AlertTitle>{'You are in impersonation mode.'}</AlertTitle>
          {isNccerBillingApp
            ? 'Close window and switch back to SSO portal to complete impersonation mode'
            : 'Click sign out on the left navigation panel to turn off impersonation mode and switch to your own account'}
        </Alert>
      )}
      <div className={styles.container} data-testid={'main-layout-container'}>
        <Sidebar />
        <div className={styles.layoutContent}>
          <AppProtectedRoutes />
        </div>
      </div>
    </>
  );
};
export default MainPageLayout;
