import React from 'react';
import { Button, Typography } from '@mui/material';
import Table from 'components/shared/Table';
import { TableColumn } from 'store/types/Table';
import { DocumentRetrieval, isErrorStatus, isRetrievable } from 'store/types/DocumentRetrieval';
import { TranslateReportName } from 'store/types/Reports';
import moment from 'moment';

interface RetrievalTableProps {
  documents: Array<DocumentRetrieval>;
  className?: string;
  onClick: (document: DocumentRetrieval) => void;
  refreshClick: () => void;
  showType?: boolean;
}

const RetrievalTable: React.FunctionComponent<RetrievalTableProps> = ({
  documents: reports,
  className = '',
  onClick: onClick,
  refreshClick: refreshClick,
  showType = false,
}) => {
  const tableColumns: Array<TableColumn<DocumentRetrieval>> = [
    {
      dataIndex: 'programName',
      label: <Typography fontWeight="bold">Program</Typography>,
      render: (_, record) => <Typography>{record.programName ?? record.programId}</Typography>,
      //sortable: true,
    },
    {
      dataIndex: 'dateRange',
      label: <Typography fontWeight="bold">Selected Date Range</Typography>,
      render: (_, record) => <Typography>{record.dateRange}</Typography>,
      //sortable: true,
    },
    {
      dataIndex: 'dateGenerated',
      label: <Typography fontWeight="bold">Date Generated</Typography>,
      render: (_, record) => (
        <Typography>
          {record.dateGenerated != null
            ? moment(record.dateGenerated).local().format('MM/DD/yyyy h:mm A').toString()
            : 'Invalid Generation Date'}
        </Typography>
      ),
      //sortable: true,
    },
    {
      key: 'retrieveFile',
      label: (
        <Button variant={'contained'} color={'primary'} onClick={refreshClick}>
          Refresh
        </Button>
      ),
      render: (_, record) =>
        isErrorStatus(record) ? (
          record.error?.includes('ReportNotGenerated') ? (
            <Typography>{'No data found'}</Typography>
          ) : (
            <Typography>{'Failed to generate'}</Typography>
          )
        ) : isRetrievable(record) ? (
          <Button onClick={() => onClick(record)} variant={'text'} color={'secondary'}>
            <Typography fontWeight="bold" color={'secondary'}>
              {'Retrieve File'}
            </Typography>
          </Button>
        ) : (
          <Typography>{'Processing...'}</Typography>
        ),
      align: 'right',
    },
  ];
  if (showType) {
    tableColumns.unshift({
      key: 'reportType',
      label: <Typography fontWeight="bold">Report Type</Typography>,
      render: (_, record) => <Typography>{TranslateReportName(record)}</Typography>,
      //sortable: true,
    });
  }

  return (
    <Table
      list={reports}
      columns={tableColumns}
      noResultsText="No documents have been requested"
      className={className}
    />
  );
};

export default RetrievalTable;
