import React, { useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import Spinner from 'components/shared/Spinner';
import { ModalProps } from 'store/types/ComponentProps';

import styles from './Modal.module.scss';
import commonStyles from 'styles/common.module.scss';
import { Close } from '@mui/icons-material';

interface ModalComponentProps extends Pick<ModalProps, 'open'> {
  onClose?: () => void;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  loading?: boolean;
  keepMounted?: boolean;
  disableBackdropClick?: boolean;
  paperClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  subTitleText?: string;
  headerBodyDivider?: boolean;
  footerBodyDivider?: boolean;
}

const Modal: React.FunctionComponent<ModalComponentProps> = ({
  open,
  title = null,
  loading = false,
  onClose,
  children,
  actions,
  keepMounted = false,
  disableBackdropClick = true,
  maxWidth = 'sm',
  contentClassName = '',
  footerClassName = '',
  subTitleText = '',
  headerBodyDivider = false,
  footerBodyDivider = false,
}) => {
  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>, reason: string) => {
      if (onClose && (reason !== 'backdropClick' || !disableBackdropClick)) {
        onClose();
      }
    },
    [disableBackdropClick, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={keepMounted}
      maxWidth={maxWidth}
      componentsProps={{ backdrop: { 'data-testid': 'backdrop' } as any }}
      data-testid={'modal'}
    >
      {(title || onClose) && (
        <DialogTitle className={classNames(styles.header)} data-testid={'modal-header'}>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant={'h2'} className={styles.title}>
                {title}
              </Typography>
              <Typography variant={'body1'} className={styles.subTitleText}>
                {subTitleText}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {onClose && (
                <IconButton
                  className={styles.closeButton}
                  onClick={onClose}
                  disabled={loading}
                  color={'primary'}
                  data-testid={'modal-close'}
                >
                  <Close />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      {headerBodyDivider && <Divider className={commonStyles.divider} />}
      <form className={styles.form}>
        <Spinner loading={loading} className={styles.spinner}>
          <DialogContent className={classNames(styles.content, contentClassName)} data-testid={'modal-content'}>
            {children}
          </DialogContent>

          {actions && footerBodyDivider && <Divider className={commonStyles.divider} />}
          {actions && (
            <DialogActions className={classNames(styles.footer, footerClassName)} data-testid={'modal-footer'}>
              <Grid container={true} justifyContent={'space-between'} className={styles.footerRow}>
                {actions}
              </Grid>
            </DialogActions>
          )}
        </Spinner>
      </form>
    </Dialog>
  );
};
export default Modal;
