import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpError, HttpService } from 'services/HttpService';
import { DocumentRetrieval, DocumentSource } from 'store/types/DocumentRetrieval';
import { Program } from 'store/types/User';

interface ReportQueueResponse {
  ReportId: number;
}
export interface ReportCountResponse {
  documents: number;
  files: number;
}

class ReportService {
  public static getAssessmentResultsOverall(
    programId: number | string,
    startDate: string,
    endDate: string,
    format: string
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      HttpService.getFileDownload(
        `${API_URL}/reports/assessmentResultsOverall?programId=${programId}&startDate=${startDate}&endDate=${endDate}&format=${format}`,
        {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }
      )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error: HttpError) => {
          if (error?.code == 404) reject('No data was found. Please alter your parameters.');
          if (error?.code == 500) reject('An unexpected error occurred.');
          reject;
        });
    });
  }

  public static getAssessmentSummary(
    programId: number | string,
    startDate: string,
    endDate: string,
    format: string
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      HttpService.getFileDownload(
        `${API_URL}/reports/assessmentSummary?programId=${programId}&startDate=${startDate}&endDate=${endDate}&format=${format}`,
        {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }
      )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error: HttpError) => {
          if (error?.code == 404) reject('No data was found. Please alter your parameters.');
          if (error?.code == 500) reject('An unexpected error occurred.');
          reject;
        });
    });
  }

  public static getSiteTrainingPrescription(
    programId: number | string,
    startDate: string,
    endDate: string,
    format: string
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      HttpService.getFileDownload(
        `${API_URL}/reports/siteTrainingPrescription?programId=${programId}&startDate=${startDate}&endDate=${endDate}&format=${format}`,
        {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }
      )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error: HttpError) => {
          if (error?.code == 404) reject('No data was found. Please alter your parameters.');
          if (error?.code == 500) reject('An unexpected error occurred.');
          reject;
        });
    });
  }

  public static getPerformanceVerifications(
    programId: number | string,
    startDate: string,
    endDate: string,
    format: string
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      HttpService.getFileDownload(
        `${API_URL}/reports/performanceVerifications?programId=${programId}&startDate=${startDate}&endDate=${endDate}&format=${format}`,
        {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }
      )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error: HttpError) => {
          if (error?.code == 404) reject('No data was found. Please alter your parameters.');
          if (error?.code == 500) reject('An unexpected error occurred.');
          reject;
        });
    });
  }

  public static getPracticalExamDetails(
    programId: number | string,
    startDate: string,
    endDate: string,
    format: string
  ): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      HttpService.getFileDownload(
        `${API_URL}/reports/practicalExamDetails?programId=${programId}&startDate=${startDate}&endDate=${endDate}&format=${format}`,
        {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }
      )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error: HttpError) => {
          if (error?.code == 404) reject('No data was found. Please alter your parameters.');
          if (error?.code == 500) reject('An unexpected error occurred.');
          reject;
        });
    });
  }

  public static getSuperIntendentAssessmentScoreReportLink(nccerNumbers: string): Promise<string> {
    return new Promise<string>((resolve: (data: string) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/reports/superIntendentAssessment?nccerNumbers=${nccerNumbers}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: string) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  // TODO: Move CreditEngineService calls into this Service and debug this method
  public static queueReport(
    programId: number | string,
    contentTypeId: number | string,
    startDate: string,
    endDate: string,
    requesterId: string,
    format: string
  ): Promise<ReportQueueResponse> {
    return new Promise<ReportQueueResponse>(
      (resolve: (data: ReportQueueResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${API_URL}/reports/queue`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          data: {
            programId,
            contentTypeId,
            startDate,
            endDate,
            requesterId,
            format,
          },
        })
          .toPromise()
          .then((rawResponse: any) => {
            const parsedResponse: ReportQueueResponse = {
              ReportId: rawResponse.report_id,
            };
            resolve(parsedResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static queueReportIneligibility(
    programId: number | string,
    requesterId: string,
    format: string
  ): Promise<ReportQueueResponse> {
    return new Promise<ReportQueueResponse>(
      (resolve: (data: ReportQueueResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${API_URL}/reports/queue/ineligibility`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          data: {
            programId,
            requesterId,
            format,
          },
        })
          .toPromise()
          .then((rawResponse: any) => {
            const parsedResponse: ReportQueueResponse = {
              ReportId: rawResponse.report_id,
            };
            resolve(parsedResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static queueReportFullProgramActivity(
    programId: number | string,
    startDate: string,
    endDate: string,
    requesterId: string,
    format: string,
    showFailures: boolean
  ): Promise<ReportQueueResponse> {
    return new Promise<ReportQueueResponse>(
      (resolve: (data: ReportQueueResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${API_URL}/reports/queue/fullProgramActivity`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          data: {
            programId,
            startDate,
            endDate,
            requesterId,
            format,
            showFailures,
          },
        })
          .toPromise()
          .then((rawResponse: any) => {
            const parsedResponse: ReportQueueResponse = {
              ReportId: rawResponse.report_id,
            };
            resolve(parsedResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static requestBulkCertificateCount(
    programId: number | string,
    startDate: string,
    endDate: string,
    requesterId: string
  ): Promise<ReportCountResponse> {
    return new Promise<ReportCountResponse>(
      (resolve: (data: ReportCountResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${API_URL}/reports/requestBulkCertCount`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          data: {
            programId,
            startDate,
            endDate,
            requesterId,
          },
        })
          .toPromise()
          .then((rawResponse: ReportCountResponse) => {
            // const parsedResponse: ReportCountResponse = {
            //   recordCount: rawResponse.RecordCount,
            // };
            resolve(rawResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static queueBulkCertificate(
    programId: number | string,
    startDate: string,
    endDate: string,
    requesterId: string
  ): Promise<ReportQueueResponse> {
    return new Promise<ReportQueueResponse>(
      (resolve: (data: ReportQueueResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${API_URL}/reports/requestBulkCertificates`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          data: {
            programId,
            startDate,
            endDate,
            requesterId,
          },
        })
          .toPromise()
          .then((rawResponse: number) => {
            const parsedResponse: ReportQueueResponse = {
              ReportId: rawResponse,
            };
            resolve(parsedResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static getRequestedBulkCertificatesByNccerNumber(
    nccerNumber: string,
    userPrograms: Program[]
  ): Promise<DocumentRetrieval[]> {
    return new Promise<DocumentRetrieval[]>(
      (resolve: (data: DocumentRetrieval[]) => void, reject: (error: HttpError) => void) => {
        HttpService.get(`${API_URL}/reports/getRequestedBulkCertificates/${nccerNumber}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        })
          .toPromise()
          .then((data: DocumentRetrieval[]) => {
            data.forEach((element) => {
              element.documentSource = DocumentSource.CredentialEngine;
              const program = userPrograms.find((up) => up.programId == element.programId);
              if (program) {
                if (program.programType === 'Training Program') {
                  element.programName = program.programName + ' (Training)';
                } else if (program.programType === 'Assessment Program') {
                  element.programName = program.programName + ' (Assessment)';
                } else {
                  element.programName = program.programName;
                }
              }
            });
            resolve(data);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static getRequestedReportsByNccerNumber(
    nccerNumber: string,
    userPrograms: Program[]
  ): Promise<DocumentRetrieval[]> {
    return new Promise<DocumentRetrieval[]>(
      (resolve: (data: DocumentRetrieval[]) => void, reject: (error: HttpError) => void) => {
        HttpService.get(`${API_URL}/reports/getRequestedReports/${nccerNumber}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        })
          .toPromise()
          .then((data: DocumentRetrieval[]) => {
            data.forEach((element) => {
              element.documentSource = DocumentSource.CredentialEngine;
              if (element.programId.includes(',')) {
                const programIds = element.programId.split(',');
                element.programName = '';
                programIds.forEach((ele, index) => {
                  if (ele != null) {
                    const program = userPrograms.find((up) => up.programId == ele);
                    if (program) {
                      if (program.programType === 'Training Program') {
                        element.programName += program.programName + ' (Training)';
                      } else if (program.programType === 'Assessment Program') {
                        element.programName += program.programName + ' (Assessment)';
                      } else {
                        element.programName += program.programName;
                      }

                      if (index != programIds.length - 1) {
                        element.programName += ', ';
                      }
                    }
                  }
                });
              } else {
                const program = userPrograms.find((up) => up.programId == element.programId);
                if (program) {
                  if (program.programType === 'Training Program') {
                    element.programName = program.programName + ' (Training)';
                  } else if (program.programType === 'Assessment Program') {
                    element.programName = program.programName + ' (Assessment)';
                  } else {
                    element.programName = program.programName;
                  }
                }
              }
            });
            resolve(data);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }
}

export default ReportService;
