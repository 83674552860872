import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpError, HttpService } from 'services/HttpService';
import { Completions } from 'store/types/Completions';
import { CredentialSearchResults } from 'store/types/CredentialSearchResults';
import { Credentials } from 'store/types/Credentials';

class CredentialService {
  public static getMyCredentials(nccerNumber: string): Promise<Credentials> {
    return new Promise<Credentials>((resolve: (data: Credentials) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/credentials?nccerNumber=${nccerNumber}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: Credentials) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static getCompletions(nccerNumber: string, partials = 'true', oq = true): Promise<Completions> {
    return new Promise<Completions>((resolve: (data: Completions) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/credentials/completions?nccerNumber=${nccerNumber}&partials=${partials}&oq=${oq}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: Completions) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static search(searchText: string, alternateIdType?: string | null): Promise<CredentialSearchResults> {
    return new Promise<CredentialSearchResults>(
      (resolve: (data: CredentialSearchResults) => void, reject: (error: HttpError) => void) => {
        const url = new URL(`${API_URL}/credentials/search`);
        const formattedSearch = searchText.replaceAll('\n', ';');
        url.searchParams.append('searchText', formattedSearch);
        if (alternateIdType) {
          url.searchParams.append('alternateIdType', alternateIdType);
        }
        url.searchParams.append('partials', 'true');
        HttpService.get(url.href, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        })
          .toPromise()
          .then((data: CredentialSearchResults) => {
            resolve(data);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static publicSearch(searchText: string): Promise<Completions> {
    return new Promise<Completions>((resolve: (data: Completions) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/credentials/publicSearch?searchText=${searchText}`, {
        headers: {
          TenantId: tenantConfig.id,
        },
      })
        .toPromise()
        .then((data: Completions) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static claimWalletCard(): Promise<boolean> {
    return new Promise<boolean>((resolve: (data: boolean) => void, reject: (error: HttpError) => void) => {
      HttpService.post(`${API_URL}/credentials/claimWalletCard`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: boolean) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static getTranscriptLink(nccerNumber: string): Promise<string> {
    return new Promise<string>((resolve: (data: string) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/credentials/transcript?nccerNumber=${nccerNumber}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: string) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static getTranscriptPipelineLink(nccerNumber: string): Promise<string> {
    return new Promise<string>((resolve: (data: string) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/credentials/transcript-pipeline?nccerNumber=${nccerNumber}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: string) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static getDocumentLink(referenceLink: string): Promise<string> {
    return new Promise<string>((resolve: (data: string) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/credentials/documentLink?referenceLink=${encodeURIComponent(referenceLink)}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: string) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }
}

export default CredentialService;
