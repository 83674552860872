import React, { useCallback, useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { UserContext } from 'components/UserGuard';
import * as PerformanceAppService from 'services/api/PerformanceAppService';
import { isAssessmentProgram, ProgramType } from 'store/types/User';
import { defaultFormProps } from 'util/Form';
import { errorMessage } from 'util/Request';
import { nameof } from 'util/String';
import { ActionButtons, CardNumberInput, DateRangeInput, ProgramSelect, TextInput } from './components';
import { ReportContext } from '../ReportsPage/context';

type Values = {
  programId: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  learnerCardNumber: string;
  evaluatorCardNumber: string;
  moduleId: string;
};
const defaultValues: Values = {
  programId: '',
  startDate: null,
  endDate: null,
  learnerCardNumber: '',
  evaluatorCardNumber: '',
  moduleId: '',
};

interface PerformanceEvaluationReportFormProps {
  programType: ProgramType;
}

export function PerformanceEvaluationReportForm({ programType }: PerformanceEvaluationReportFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { programs = [] } = useContext(UserContext);
  const { closeModal, setLoading, refreshDocumentList } = useContext(ReportContext);
  const form = useForm<Values>({
    ...defaultFormProps,
    defaultValues,
  });
  const { formState, handleSubmit } = form;

  const isAssessment = isAssessmentProgram({ programType });
  const submitDisabled = !formState.isValid;

  const handleFormSubmit = useCallback(
    async ({ programId, startDate, endDate, learnerCardNumber, evaluatorCardNumber, moduleId }: Values) => {
      if (!programId || !startDate || !endDate) return;

      setLoading(true);
      try {
        await PerformanceAppService.reportSubmission({
          programId,
          startDate: startDate.startOf('day').format(), // Inclusive start date.
          endDate: endDate.endOf('day').format(), // Inclusive end date.
          learnerCardNumber: learnerCardNumber || undefined,
          evaluatorCardNumber: evaluatorCardNumber || undefined,
          moduleId: moduleId || undefined,
        });
        refreshDocumentList();
        enqueueSnackbar(`Report queued successfully.`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
      } finally {
        setLoading(false);
        closeModal();
      }
    },
    [closeModal, enqueueSnackbar, refreshDocumentList, setLoading]
  );

  return (
    <FormProvider {...form}>
      <Grid container spacing={4}>
        <ProgramSelect name={nameof<Values>('programId')} programs={programs} programType={programType} />
        <DateRangeInput nameStartDate={nameof<Values>('startDate')} nameEndDate={nameof<Values>('endDate')} />
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>
            {'To further narrow your report results, you may enter any of the following (optional) information.'}
          </Typography>
        </Grid>
        <CardNumberInput
          name={nameof<Values>('learnerCardNumber')}
          label={isAssessment ? 'Candidate NCCER Number' : 'Learner NCCER Number'}
        />
        <CardNumberInput name={nameof<Values>('evaluatorCardNumber')} label="Evaluator NCCER Number" />
        <TextInput
          name={nameof<Values>('moduleId')}
          label={isAssessment ? 'PV Title' : 'Module Number'}
          placeholder={isAssessment ? 'Industrial Electrician' : 'CT14_1-17'}
        />
      </Grid>
      <ActionButtons
        submitText="Generate Report"
        disabled={submitDisabled}
        onClose={closeModal}
        onSubmit={handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
}
