import React, { useCallback, useContext } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { ActionButtons, CardNumberMultiInput } from './components';
import { ReportContext } from '../ReportsPage/context';

type Values = typeof defaultValues;
const defaultValues = {
  nccerNumbers: '',
};

export function ConstructionSuperintendentReportForm() {
  const { closeModal, getSuperintendentReport } = useContext(ReportContext);
  const form = useForm<Values>({
    ...defaultFormProps,
    defaultValues,
  });
  const { formState, handleSubmit } = form;

  const submitDisabled = !formState.isValid || !formState.isDirty;

  const handleFormSubmit = useCallback(
    (data: Values) => getSuperintendentReport(data.nccerNumbers),
    [getSuperintendentReport]
  );

  return (
    <FormProvider {...form}>
      <Grid container spacing={4}>
        <CardNumberMultiInput name="nccerNumbers" label="NCCER Numbers" />
      </Grid>
      <ActionButtons
        submitText="Generate File"
        disabled={submitDisabled}
        onClose={closeModal}
        onSubmit={handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
}
