import Address from 'store/types/Address';
import { Moment } from 'moment-timezone';
import { UserJobFunction } from 'store/types/JobFunction';

export interface PublicUser {
  id: string;
  firstName: string;
  lastName: string;
  middleInitial?: string;
  logoUrl: string | null;
  photoData: File | null;
  address: Address;
  email: string;
  phone?: string;
  functions?: UserJobFunction[];

  race: string | null;
  ethnicity: string | null;
  gender: string | null;
  //primaryLanguage: string | null;
  nativeLanguage: string | null;

  walletCardStatus: string | null;
  walletCardStatusDate: string | Date | null;
  synchronizeWithNetSuite: boolean;
  applyFerpaAgeRestriction: boolean;
  craftProJobsiteEvaluatorStatus: string;
  canClaimWalletCard: boolean;

  title: string | null;
  birthDate: string | Moment | null;
  birthCity: string | null;
  alternateId: string | null;
  alternateIdType: string | null;
  nccerCardNumber?: string;
  currentRole?: string;
  roles?: UserRole[];
  programs?: Program[];
}

export type UserRole = {
  roleIsActive: boolean;
  role: string;
  programId: number;
  endDate: string | null;
  crafts: string;
};

export type ProgramType = typeof ProgramType[keyof typeof ProgramType];
export const ProgramType = {
  Training: 'Training Program',
  Assessment: 'Assessment Program',
} as const;

export type Program = {
  programId: string;
  programName: string;
  programType: ProgramType;
  programDesignation: string;
  approvedForPaperTesting: string;
  cardNumberCreatorOverride: boolean;
};

export function isTrainingProgram(program: { programType: ProgramType } | undefined) {
  return program?.programType === ProgramType.Training;
}

export function isAssessmentProgram(program: { programType: ProgramType } | undefined) {
  return program?.programType === ProgramType.Assessment;
}

type User = PublicUser & {
  creditBalance?: number;

  releaseForm?: boolean;
  releaseFormDate?: Date | null;
  releaseFormOrigin?: string | null;

  releaseCLMA?: boolean;

  credlyUserAcceptedFlag?: boolean;
  credlyUserAcceptedDate?: Date | null;
};

export interface UserView extends Omit<User, 'birthDate'> {
  birthDate: Date | null;
}

export type SetCurrentUserValue = Partial<Omit<User, 'birthDate'> & { birthDate: Moment | string | null }>;

export interface CurrentUser extends UserView {
  setCurrentUser: (newValue: SetCurrentUserValue) => void;
  refetchUser: () => void;
  userLoading?: boolean;
}

export type NewUser = Omit<
  User,
  | 'id'
  | 'email'
  | 'birthDate'
  | 'functions'
  | 'synchronizeWithNetSuite'
  | 'applyFerpaAgeRestriction'
  | 'craftProJobsiteEvaluatorStatus'
  | 'canClaimWalletCard'
> &
  Partial<Pick<User, 'id' | 'email'>> & {
    birthDate: Date | null;
    //premium?: boolean;
  };

export default User;
