import React from 'react';
import { Button, Typography } from '@mui/material';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import legalInformation from 'store/configs/Legal';

const TermsAndConditionsModal: React.FunctionComponent<ModalProps> = ({ open, loading, onClose }) => {
  return (
    <Modal
      loading={loading}
      open={open}
      title={'NCCER User Terms & Conditions'}
      maxWidth={'sm'}
      onClose={onClose}
      disableBackdropClick={false}
      actions={
        <Button color={'primary'} variant={'contained'} onClick={onClose}>
          {'Close'}
        </Button>
      }
    >
      {legalInformation.map((i) => (
        <Typography key={`legal-para-1`} paragraph={true}>
          {i}
        </Typography>
      ))}
    </Modal>
  );
};
export default TermsAndConditionsModal;
