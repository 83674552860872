import React, { useCallback } from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';

import styles from './OnlineVerification.module.scss';
import searchNormal from 'assets/icons/searchNormal.svg';
import { useSnackbar } from 'notistack';
import NCCER_logo_wordmark_positive_RGB from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import { useHistory } from 'react-router-dom';
import routes from 'store/configs/Routes';

const OnlineVerification: React.FunctionComponent = () => {
  const [searchVal, setSearchVal] = React.useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const searchNumbers = useCallback(() => {
    if (!searchVal || searchVal == '') {
      enqueueSnackbar('Please provide a search parameter.', { variant: 'error' });
    } else {
      history.push(`${routes.ovSearch}/${searchVal}`);
    }
  }, [searchVal, enqueueSnackbar, history]);

  const handleEnterKey = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      searchNumbers();
    }
  };
  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.currentTarget.value);
  };

  return (
    <div className={styles.ovContent}>
      <img src={NCCER_logo_wordmark_positive_RGB} />
      <Typography variant={'h1'} className={styles.unsearchedTitle}>
        {'Credential Search'}
      </Typography>
      <Typography variant={'h5'} className={styles.unsearchedDescription}>
        {"Search and verify individuals' credentials with their NCCER Number OR their Last Name and SSN"}
      </Typography>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextField
            placeholder={'12345678 or Smith 123-45-6789'}
            value={searchVal}
            size={'small'}
            onChange={handleSearchInput}
            onKeyDown={handleEnterKey}
            className={styles.searchBar}
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  <img src={searchNormal} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
};
export default OnlineVerification;
