import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Controller, FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import {
  countryFieldName,
  FormProps,
  getAddressControlName,
  getRequiredValidationRule,
  getValidationProps,
  isCanadaSelected,
  isUSSelected,
} from 'util/Form';
import { caStates, usStates } from 'store/configs/States';
import StaticSelectOption from 'store/types/StaticSelectOption';

import externalStyles from 'components/profile/EditProfilePageView/EditProfileForm/EditProfileForm.module.scss';

interface StateFormItemProps extends FormProps {
  addressFieldName: string;
}

const stateFieldName = 'stateRegion';

const getOptions = (states: StaticSelectOption[] = []): React.ReactNode[] =>
  states.map(({ text, value }: StaticSelectOption) => (
    <MenuItem value={value} key={`state-${value}`} data-testid={`${stateFieldName}-option`}>
      {text}
    </MenuItem>
  ));

const caOptions = getOptions(caStates);
const usOptions = getOptions(usStates);

const StateFormItem: React.FunctionComponent<StateFormItemProps> = ({ addressFieldName, disabled = false }) => {
  const {
    control,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext();
  const countryControlName = useMemo(
    () => getAddressControlName(addressFieldName, countryFieldName),
    [addressFieldName]
  );
  const touchedFieldValues = addressFieldName ? touchedFields[addressFieldName] : touchedFields;
  const addressErrors = addressFieldName ? (errors[addressFieldName] as FieldErrors) : errors;
  const stateControlName = useMemo(() => getAddressControlName(addressFieldName, stateFieldName), [addressFieldName]);
  const countryValue = useWatch({ control, name: countryControlName });
  const label: string = useMemo(() => (isCanadaSelected(countryValue) ? 'Province' : 'State'), [countryValue]);
  const options: React.ReactNode[] = useMemo(
    () => (isUSSelected(countryValue) ? usOptions : isCanadaSelected(countryValue) ? caOptions : []),
    [countryValue]
  );
  const fieldRequired: boolean = useMemo(
    () => isUSSelected(countryValue) || isCanadaSelected(countryValue),
    [countryValue]
  );

  useEffect(() => {
    if (
      touchedFieldValues &&
      touchedFieldValues[countryFieldName] &&
      options &&
      (isUSSelected(countryValue) || isCanadaSelected(countryValue))
    ) {
      setValue(stateControlName, '');
    }
  }, [touchedFieldValues, options, countryValue, stateControlName, setValue]);

  const handleChange = useCallback(
    (onChange, onBlur) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
      onBlur();
    },
    []
  );

  return (
    <Controller
      render={({ field: { onChange, value, onBlur } }) => (
        <TextField
          // {...field}
          {...getValidationProps('stateRegion', addressErrors)}
          onChange={handleChange(onChange, onBlur)}
          required={fieldRequired}
          disabled={disabled}
          className={externalStyles.inputField}
          select={true}
          value={value}
          //label={label}
          inputProps={{ 'data-testid': `${stateFieldName}-input` }}
          data-testid={`${stateFieldName}-text-field`}
          FormHelperTextProps={{ 'data-testid': `${stateFieldName}-helper-text` } as any}
          SelectProps={{
            SelectDisplayProps: { 'data-testid': `${stateFieldName}-select-button` } as any,
            MenuProps: { 'data-testid': `${stateFieldName}-menu` } as any,
          }}
        >
          {options}
        </TextField>
      )}
      name={stateControlName}
      control={control}
      rules={{
        required: getRequiredValidationRule(label.toLowerCase(), true, fieldRequired),
      }}
    />
  );
};
export default StateFormItem;
