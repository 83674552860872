import NodeProcess from './store/types/NodeProcess';
import TenantConfig from './store/types/TenantConfig';

declare const process: NodeProcess;

// This is the only non-react-app env variable supported.
const isProduction: boolean = process.env.NODE_ENV === 'production';

// Environment variables besides NODE_ENV must begin with REACT_APP_
//   to be compiled into the webpack for static deploys.
export const buildVersion = process.env.REACT_APP_BUILD_VERSION || 'Number.Hash';

export const theme = process.env.REACT_APP_THEME || 'default';

export const INTERNAL_IDP_TYPE = 'internal';
export const EXTERNAL_IDP_TYPE = 'external';

// TODO: move these calls to the middleware to protect token
export const CE_API_TOKEN_TEMP = '2b257c62390e75a2254b08f47f6b26a1e627b1b1588b46804a0abe93c3f7af9b';

export const tenantConfig: TenantConfig = {
  id: process.env.REACT_APP_TENANT || '10',
  clientId: process.env.REACT_APP_IDS_CLIENT_ID || '',
  theme: process.env.REACT_APP_THEME || '',
  environment: process.env.REACT_APP_ENVIRONMENT || '',
  appType: process.env.REACT_APP_TYPE || '',
  idpType: process.env.REACT_APP_IDP_TYPE || INTERNAL_IDP_TYPE,
  idsUrl: process.env.REACT_APP_IDS_URL || '',
  appTitle: process.env.REACT_APP_TITLE || 'NCCER Account Portal',
  userflowToken: process.env.REACT_APP_USERFLOW_TOKEN || '', // does not exist in dev
  addNccerCardNumberUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_ADD_NCCER_CARD_NUMBER_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_ADD_NCCER_CARD_NUMBER_PATH}`
      : '',
  editProfileUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_EDIT_PROFILE_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_EDIT_PROFILE_PATH}`
      : '',
  loginUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_LOGIN_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_LOGIN_PATH}`
      : 'https://ids-us-east-1.dev.nccer.net/connect/authorize?client_id=10webportal&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F%23%2FredirectUrl%3F&scope=openid&response_type=id_token&response_mode=fragment&state=ihwyhbmap4&nonce=a7gc1l51x5s',
  logoutUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_LOGOUT_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_LOGOUT_PATH}`
      : 'https://ids-us-east-1.dev.nccer.net/connect/endsession?post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F%23%2FredirectUrl%3F',
  changePasswordUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CHANGE_PASSWORD_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CHANGE_PASSWORD_PATH}`
      : 'https://test-registry.nccer.org/Account/ChangePassword/',
  changeEmailUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CHANGE_EMAIL_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CHANGE_EMAIL_PATH}`
      : 'https://test-registry.nccer.org/Account/ChangeEmail/',
  confirmEmailUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CONFIRM_EMAIL_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CONFIRM_EMAIL_PATH}`
      : 'https://test-registry.nccer.org/Account/ChangeEmail/',
  forgotCardNumberUrl:
    process.env.REACT_APP_FORGOT_CARD_NUMBER_URL || 'https://test-registry.nccer.org/Account/ForgotCardNumber',
  checkSessionUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_CHECK_SESSION_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_CHECK_SESSION_PATH}`
      : '',
  reLoginUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_RELOGIN_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_RELOGIN_PATH}`
      : '',
  impersonationUrl:
    process.env.REACT_APP_IDS_URL && process.env.REACT_APP_IMPERSONATION_PATH
      ? `${process.env.REACT_APP_IDS_URL}${process.env.REACT_APP_IMPERSONATION_PATH}`
      : '',
  replaceWalletCardUrl:
    process.env.REACT_APP_WALLET_CARD_URL ||
    'https://store.nccer.org/products/nccer-wallet-card?_pos=1&_sid=a1968d09a&_ss=r',
  ceApiUrl: process.env.REACT_APP_CE_API_URL || 'https://ce.stg.nccer.net',
  performanceAppUrl: process.env.REACT_APP_PERFORMANCE_APP_URL || 'https://pp.stg.nccer.net',
  scoreAppUrl: process.env.REACT_APP_SCORE_APP_URL || 'https://score.stg.nccer.net/auth?id_token=',
};
// id default to 2 for test netsuite tenant

const secondsToMillis = (seconds: number): number => {
  return seconds * 1000;
};

export const conf = {
  isProduction: isProduction,
  useXHRLogger: false,
  buildVersion: buildVersion,
  pathRoot: '/',
  staticRoot: '/index.html',
  httpRetryInterval: secondsToMillis(3),
  httpRequestTimeout: secondsToMillis(300),
  httpRetryLimit: 0,
  httpRequestTimeoutRetryLimit: 5,
  defaultDownloadedFileName: 'download',
  defaultErrMessage: 'An error occurred. Please try again later.',
  filenameHeader: 'x-suggested-filename',
  defaultLanguage: 'en_US',
  api: {
    logs: '/api/logs',
  },
};

export const Support_Portal_Link =
  'https://nccer.my.site.com/Support/s/?_gl=1*4bvwir*_ga*MTA0MjAzODMzNS4xNjU4ODQ3MjMz*_ga_8DZWYQF35R*MTY4MDIwNDc3MS40Mi4wLjE2ODAyMDQ3NzEuMC4wLjA';

export const Credly_Portal_Link =
  'https://www.credly.com/organizations/national-center-for-construction-education-research/badges';

// export const API_URL = process.env.REACT_APP_API_URL || "https://localhost:6001";
export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api2-us-east-1.dev.nccer.net/us-east-1-dev-profile/profile';

// LEGACY
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || 'https://api.dev.nccer.net/au';
